
import { useContext } from 'react';
import { Link, Outlet } from 'react-router-dom'
import { CinemaModeContext } from '../player';

const Header = () => {
  const { cinemaMode } = useContext(CinemaModeContext);
  const navbarEnabled = !cinemaMode;
  return (
    <div>
      <main className={`${navbarEnabled && "pt-20 md:pt-24"}`}>
        <Outlet />
      </main>
    </div>
  )
}

export default Header
